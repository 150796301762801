const hasPermission = (permission: string): boolean => {
  if (!localStorage.getItem("user")) {
    window.location.href = "/#/sign-in";
    location.reload();
  }
  if (JSON.parse(localStorage.getItem("user") || "")["perfil_id"] == 1) {
    return true;
  }
  const permissions = JSON.parse(localStorage.getItem("user") || "[]")[
    "permissoes"
  ];
  return permissions.includes(permission);
};

export default hasPermission;
