const ID_TOKEN_KEY = "id_token" as string;
const USER = "user" as string;
export interface User {
  id: string;
  nome: string;
  email: string;
  cpf: string;
  created_at: string;
}

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description salvar as informações do usuário logado no localStorage
 */
export const saveUser = (user: string): void => {
  window.localStorage.setItem(USER, user);
};

export const getUser = (): User => {
  const userSting = window.localStorage.getItem(USER);
  const userObj = JSON.parse(userSting || "");
  return userObj;
};

export const destroyUser = (): void => {
  window.localStorage.removeItem(USER);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveUser,
  getUser,
  destroyUser,
};
