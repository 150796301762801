import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import { useRouter } from "vue-router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  // private static router = useRouter();
  /**
   * @description initialize vue axios
   */
  // public static init(app: App<Element>) {
  //   ApiService.vueInstance = app;
  //   ApiService.vueInstance.use(VueAxios, axios);
  //   ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  // }

  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    // ApiService.vueInstance.axios.defaults.baseURL =
    //   process.env.VUE_APP_SINDICATO_API_URL + "api/";
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.interceptors.request.use((request) => {
      // add auth header with jwt if account is logged in and request is to the api url
      // const account = accountService.accountValue;
      const isLoggedIn = JwtService.getToken();

      if (isLoggedIn) {
        request.headers.common.Authorization = `Bearer ${isLoggedIn}`;
      }

      return request;
    });

    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // console.log(error.response.data.message);
        // if (
        //   error.response.data.message == "The token has been blacklisted" ||
        //   error.response.data.message == "token_expired"
        // ) {
        //   JwtService.destroyToken();
        //   JwtService.destroyUser();
        //   window.location.href = "/#/sign-in";
        // }
        if (error.response.status) {
          switch (error.response.status) {
            case 422:
              Swal.fire({
                title: "Desculpe",
                text: error.response.data.message
                  ? error.response.data.message
                  : error.response.data.message
                  ? error.response.data.message
                  : error.response.data.error.msg,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then((result) => {
                // window.location.href = "/#/sign-in";
                location.reload();
              });
              //do something
              break;

            case 401:
              JwtService.destroyToken();
              JwtService.destroyUser();
              window.location.href = "/#/sign-in";
              location.reload();
              break;
            case 403:
              Swal.fire({
                title: "Desculpe",
                text: error.response.data.message
                  ? error.response.data.message
                  : "Você não tem permissão para realizar essa operação",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then((result) => {
                window.location.href = "/#/sign-in";
                location.reload();
              });
              break;
            // case 404:
            //   Swal.fire({
            //     title: "Desculpe",
            //     text: "Serviço indisponível. Caso o problema persista, entre em contato com o administrador do sistema.",
            //     icon: "error",
            //     buttonsStyling: false,
            //     confirmButtonText: "OK",
            //     customClass: {
            //       confirmButton: "btn btn-primary",
            //     },
            //   });
            //   break;
            case 500:
              console.log(error.response.data);
              if (error.response.data.message) {
                if (
                  error.response.data.message ==
                  "The token has been blacklisted"
                ) {
                  Swal.close();
                  JwtService.destroyToken();
                  JwtService.destroyUser();
                  window.location.href = "/#/sign-in";
                  break;
                }
              }
            //   }
            // Swal.fire({
            //   title: "Desculpe",
            //   text: "Ocorreu um erro ao tentar efetuar essa operação. Caso o problema persista, entre em contato com o administrador do sistema.",
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: "OK",
            //   customClass: {
            //     confirmButton: "btn btn-primary",
            //   },
            // });
          }
          return Promise.reject(error.response);
        }
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
