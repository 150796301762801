import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import hasPermission from "@/core/services/hasPermission";
import Swal from "sweetalert2/dist/sweetalert2.js";

const alert = () => {
  Swal.fire({
    title: "Desculpe",
    text: "Você não tem permissão para realizar essa operação",
    icon: "warning",
    buttonsStyling: false,
    confirmButtonText: "OK",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  }).then(() => {
    router.push({
      name: "dashboard",
    });
  });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("show_dash_menu")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/associado/associados-lista",
        name: "apps-associados-lista",
        component: () => import("@/views/associado/AssociadosLista.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("associado_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/associado/declaracao",
        name: "apps-associados-declaracao",
        component: () => import("@/views/associado/Declaracoes.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("associado_declaracao")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/associado/atualizar-situacao",
        name: "apps-associados-atualizar-situacao",
        component: () => import("@/views/associado/AtualizarSituacao.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("associado_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/associado/novo",
        name: "apps-associados-novo",
        component: () => import("@/views/associado/AssociadoAdd.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("associado_adicionar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/associado/:id",
        name: "apps-associados-edit",
        component: () => import("@/views/associado/AssociadoEdit.vue"),
        beforeEnter: (to, from, next) => {
          if (
            hasPermission("associado_editar") ||
            hasPermission("med_associado")
          ) {
            next();
          } else {
            alert();
          }
        },
        children: [
          {
            path: "visao-geral",
            name: "apps-associados-edit-visao-geral",
            component: () =>
              import("@/views/associado/abasEdicao/VisaoGeral.vue"),
          },
          {
            path: "dados-associado",
            name: "profile-dados-associado",
            component: () =>
              import("@/views/associado/abasEdicao/DadosAssociado.vue"),
            children: [
              {
                path: "dados-cadastro",
                name: "profile-dados-cadastro",
                component: () =>
                  import(
                    "@/views/associado/abasEdicao/abasDadosAssociado/DadosCadastro.vue"
                  ),
              },
            ],
          },
          // {
          //   path: "financeiro",
          //   name: "profile-financeiro",
          //   component: () =>
          //     import("@/views/associado/abasEdicao/Financeiro.vue"),
          // },
          {
            path: "financeiro",
            name: "profile-financeiro",
            component: () =>
              import("@/views/associado/abasEdicao/Financeiro.vue"),
            children: [
              {
                path: "cobrancas",
                name: "profile-cobranca",
                component: () =>
                  import(
                    "@/views/associado/abasEdicao/abasFinanceiro/Cobranca.vue"
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    JSON.parse(localStorage.getItem("user") || "")[
                      "perfil_id"
                    ] != 2 ||
                    !hasPermission("med_associado")
                  ) {
                    next();
                  } else {
                    alert();
                  }
                },
              },
              {
                path: "cobrancas-v2",
                name: "profile-cobranca-v2",
                component: () =>
                  import(
                    "@/views/associado/abasEdicao/abasFinanceiro/CobrancaV2.vue"
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    JSON.parse(localStorage.getItem("user") || "")[
                      "perfil_id"
                    ] != 2 ||
                    !hasPermission("med_associado")
                  ) {
                    next();
                  } else {
                    alert();
                  }
                },
              },
              {
                path: "assinatura",
                name: "profile-assinatura",
                component: () =>
                  import(
                    "@/views/associado/abasEdicao/abasFinanceiro/Assinatura.vue"
                  ),
              },
              {
                path: "dados-taxa-associativa",
                name: "profile-dados-taxa-associativa",
                component: () =>
                  import(
                    "@/views/associado/abasEdicao/abasDadosAssociado/DadosTaxaAssociativa.vue"
                  ),
              },
              {
                path: "dados-plano-saude",
                name: "profile-dados-plano-saude",
                component: () =>
                  import(
                    "@/views/associado/abasEdicao/abasDadosAssociado/DadosPlanoSaude.vue"
                  ),
              },
            ],
          },
          {
            path: "observacoes",
            name: "profile-observacoes",
            component: () =>
              import("@/views/associado/abasEdicao/Observacoes.vue"),
            beforeEnter: (to, from, next) => {
              if (
                JSON.parse(localStorage.getItem("user") || "")["perfil_id"] !=
                  2 ||
                !hasPermission("med_associado")
              ) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "historico-geral",
            name: "profile-historico-geral",
            component: () =>
              import("@/views/associado/abasEdicao/HistoricoGeral.vue"),
            beforeEnter: (to, from, next) => {
              if (
                JSON.parse(localStorage.getItem("user") || "")["perfil_id"] !=
                  2 ||
                !hasPermission("med_associado")
              ) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "historico-associacao",
            name: "profile-historico-associacao",
            component: () =>
              import("@/views/associado/abasEdicao/HistoricoAssociacao.vue"),
            beforeEnter: (to, from, next) => {
              if (
                JSON.parse(localStorage.getItem("user") || "")["perfil_id"] !=
                  2 ||
                !hasPermission("med_associado")
              ) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "cobranca-avulsa/:id_iugu",
            name: "profile-cobranca-avulsa",
            component: () =>
              import("@/views/associado/abasEdicao/CobrancaAvulsa.vue"),
            beforeEnter: (to, from, next) => {
              if (
                JSON.parse(localStorage.getItem("user") || "")["perfil_id"] !=
                  2 ||
                !hasPermission("med_associado")
              ) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "eventos",
            name: "profile-eventos-associado",
            component: () => import("@/views/associado/abasEdicao/Eventos.vue"),
          },
          {
            path: "dependentes",
            name: "profile-dependente-associacao",
            component: () =>
              import("@/views/associado/abasEdicao/Dependentes.vue"),
          },
        ],
      },
      {
        path: "/usuario/usuarios-lista",
        name: "apps-usuario-lista",
        component: () => import("@/views/usuario/UsuariosLista.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("config_usuario_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/usuario/usuarios-externos",
        name: "apps-usuario-externo",
        component: () => import("@/views/usuario/UsuariosExternoLista.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("config_usuario_externo_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/usuario/novo",
        name: "apps-usuario-novo",
        component: () => import("@/views/usuario/UsuarioAdd.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("config_usuario_adicionar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/usuario/:id",
        name: "apps-usuario-detalhe",
        component: () => import("@/views/usuario/UsuarioDetalhe.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("config_usuario_editar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/usuario-externo/:id",
        name: "apps-usuario-externo-detalhe",
        component: () => import("@/views/usuario/UsuarioExternoDetalhe.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("config_usuario_externo_editar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/contas-bancarias",
        name: "apps-conta-bancarias",
        component: () => import("@/views/contas-bancarias/ContasBancarias.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_cb_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/contas-bancarias/novo",
        name: "apps-conta-bancarias-novo",
        component: () =>
          import("@/views/contas-bancarias/ContasBancariasAdd.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_cb_adicionar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/contas-bancarias/:id",
        name: "apps-conta-bancarias-edit",
        component: () =>
          import("@/views/contas-bancarias/ContasBancariasEdit.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_cb_editar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/centro-custo/",
        name: "apps-centro-custo",
        component: () => import("@/views/centro-custo/CentroCusto.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_cc_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/centro-custo/novo",
        name: "apps-centro-custo-novo",
        component: () => import("@/views/centro-custo/CentroCustoAdd.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_cc_adicionar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/centro-custo/:id",
        name: "apps-centro-custo-edit",
        component: () => import("@/views/centro-custo/CentroCustoEdit.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_cc_editar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/fornecedor/",
        name: "apps-fornecedor",
        component: () => import("@/views/fornecedor/Fornecedor.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_f_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/fornecedor/novo",
        name: "apps-fornecedor-novo",
        component: () => import("@/views/fornecedor/FornecedorAdd.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_f_adicionar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/fornecedor/:id",
        name: "apps-fornecedor-edit",
        component: () => import("@/views/fornecedor/FornecedorEdit.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_f_editar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/plano-conta",
        name: "apps-plano-conta",
        component: () => import("@/views/plano-conta/PlanoConta.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_pc_listar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/plano-conta/novo",
        name: "apps-plano-conta-add",
        component: () => import("@/views/plano-conta/PlanoContaAdd.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_pc_adicionar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/plano-conta/:id",
        name: "apps-plano-conta-edit",
        component: () => import("@/views/plano-conta/PlanoContaEdit.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("finan_pc_editar")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/resgate-cartao-credito",
        name: "apps-resgate-cartao-credito",
        component: () =>
          import("@/views/resgate-cartao-credito/ResgateCartaoCredito.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("resgate_cartao_credito")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/movimentacoes",
        name: "apps-movimentacoes",
        component: () => import("@/views/movimentacoes/Movimentacoes.vue"),
        children: [
          {
            path: "receitas",
            name: "apps-receitas",
            component: () => import("@/views/movimentacoes/abas/Receitas.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_rec_lista")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "receitas/novo",
            name: "apps-receitas-novo",
            component: () =>
              import("@/views/movimentacoes/abas/ReceitaAdd.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_rec_adicionar")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "receitas/:id",
            name: "apps-receitas-edit",
            component: () =>
              import("@/views/movimentacoes/abas/ReceitaEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_rec_editar")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "despesas",
            name: "apps-despesas",
            component: () => import("@/views/movimentacoes/abas/Despesas.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_desp_lista")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "despesas/novo",
            name: "apps-despesas-novo",
            component: () =>
              import("@/views/movimentacoes/abas/DespesaAdd.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_desp_adicionar")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "despesas/:id",
            name: "apps-despesas-edit",
            component: () =>
              import("@/views/movimentacoes/abas/DespesaEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_deso_editar")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "transferencias",
            name: "apps-transferencias",
            component: () =>
              import("@/views/movimentacoes/abas/Transferencias.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_transf_lista")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "transferencias/novo",
            name: "apps-transferencias-novo",
            component: () =>
              import("@/views/movimentacoes/abas/TransferenciaAdd.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_transf_adicionar")) {
                next();
              } else {
                alert();
              }
            },
          },
          {
            path: "transferencias/:id",
            name: "apps-transferencias-edit",
            component: () =>
              import("@/views/movimentacoes/abas/TransferenciaEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (hasPermission("finan_transf_editar")) {
                next();
              } else {
                alert();
              }
            },
          },
        ],
      },
      {
        path: "/cobranca/arquivo-remessa",
        name: "apps-arquivo-remessa",
        component: () => import("@/views/cobranca/via-arquivo/Remessa.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("cobranca_remessa")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/cobranca/arquivo-retorno",
        name: "apps-arquivo-retorno",
        component: () => import("@/views/cobranca/via-arquivo/Retorno.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("cobranca_retorno")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/cobranca/cobranca-api",
        name: "apps-cobranca-api",
        component: () => import("@/views/cobranca/via-api/OnPlay.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("cobranca_api")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/cobranca/cobranca-manual",
        name: "apps-cobranca-manual",
        component: () => import("@/views/cobranca/CobrancaManual.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("cobranca_manual")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/cobranca/cobranca-lote",
        name: "apps-cobranca-lote",
        component: () => import("@/views/cobranca/CobrancaManualLote.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("cobranca_manual")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/cobranca/cobranca-consignado",
        name: "apps-cobranca-consignado",
        component: () => import("@/views/cobranca/Consignados.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("cobranca_consignado")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/importar",
        name: "apps-importar",
        component: () => import("@/views/importacao/Importar.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("importar_sicredi_bisa")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/ingressos-uci",
        name: "apps-ingressos-uci",
        component: () => import("@/views/ingressos/IngressosUCI.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("ingressos_uci")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/relatorio/financeiro/conta-bancaria",
        name: "relatorio-conta-bancaria",
        component: () =>
          import("@/views/relatorios/RelatorioContaBancaria.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("relatorio_finan_conta_bancaria")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/relatorio/financeiro/detalhado",
        name: "relatorio-detalhado",
        component: () => import("@/views/relatorios/RelatorioDetalhado.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("relatorio_detalhado")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/relatorio/cobranca",
        name: "relatorio-cobranca",
        component: () => import("@/views/relatorios/RelatorioCobranca.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("relatorio_cobranca")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/relatorio/cartao-de-credito",
        name: "relatorio-cartao-de-credito",
        component: () =>
          import("@/views/relatorios/RelatorioCartaoCredito.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("relatorio_cartao_credito")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/relatorio/plano-de-conta",
        name: "relatorio-plano-de-conta",
        component: () => import("@/views/relatorios/RelatorioPlanoConta.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("relatorio_plano_conta")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/relatorio/centro-de-custo",
        name: "relatorio-centro-de-custo",
        component: () => import("@/views/relatorios/RelatorioCentroCusto.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("relatorio_centro_custo")) {
            next();
          } else {
            alert();
          }
        },
      },
      {
        path: "/evento/historico-retiradas",
        name: "evento-historico-retiradas",
        component: () =>
          import("@/views/dashboards/abasEvento/HistoricoRetiradas.vue"),
        beforeEnter: (to, from, next) => {
          if (hasPermission("listar_historico")) {
            next();
          } else {
            alert();
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/seja-socio-simepe",
    name: "seja-socio-simepe",
    component: () => import("@/views/cadastro-externo/SejaSocioSimepe.vue"),
  },
  {
    path: "/seja-socio-simepe/sucesso",
    name: "seja-socio-simepe-sucesso",
    component: () =>
      import("@/views/cadastro-externo/SejaSocioSimepeSucesso.vue"),
  },
  {
    path: "/cadastrar-cartao-de-credito/:id/:nome",
    name: "cadastrar-cartao-de-credito",
    component: () =>
      import("@/views/cadastro-externo/CadastrarCartaoCredito.vue"),
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
